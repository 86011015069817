.Container-opening-tree {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .Middle-pane-container {
    padding: 4vh;
  }
  
  .Board-container {
    padding: 4vh;
    margin-top: 8vh;
  }
  
  .Right-pane-container {
    padding: 4vh;
  }